<script>
import gql from 'graphql-tag'
import cloneDeep from 'lodash/cloneDeep'
import toNumber from 'lodash/toNumber'
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'

export default {
  components: {
    modalResults: () => import('./ModalDataResults.vue')
  },

  data () {
    return {
      loadingBatch: false,
      loadingFarms: false,
      loadingModules: false,
      loadingCalc: false,
      dialogCalcResult: false,
      showSupplementation: false,
      reference_month: new Date().toISOString().substr(0, 7),
      lastFarm: localStorage.getItem('lastFarm'),
      lastModule: localStorage.getItem('lastModule'),
      modalDateRef: false,
      enableMultiple: false,
      valid: null,
      dialogOccupation: false,
      params: {},
      form: {},
      farms: [],
      modules: [],
      batch: [],
      calcs: [
        { value: 'NUMBER_OF_HEADS_THAT_FIT_IN_MODULE', text: 'Quantas cabeças cabem neste módulo' },
        { value: 'NUMBER_OF_HEADS_PER_PERFORMANCE_GOAL', text: 'Quantidade de cabeças por meta de desempenho' },
        { value: 'WHAT_IS_THE_EXPECTED_WEIGHT_GAIN_IN_THIS_BATCH', text: 'Qual o ganho de peso esperado neste lote' },
      ],

      daysOccupations: [
        { value: 0.33, text: '8 horas/piquete' },
        { value: 0.5, text: '12 horas/piquete' },
        { value: 1, text: '1 dia/piquete' },
        { value: 2, text: '2 dias/piquete' },
        { value: 3, text: '3 dias/piquete' },
        { value: 4, text: '4 dias/piquete' },
        { value: 5, text: '5 dias/piquete' },
        { value: 6, text: '6 dias/piquete' },
        { value: 7, text: '7 dias/piquete' },
        { value: 10, text: '10 dias/piquete' },
        { value: 14, text: '14 dias/piquete' },
        { value: 21, text: '21 dias/piquete' },
        { value: 30, text: '30 dias/piquete' },
      ],

      occupationList: [
        { value: 0.33, text: '8h' },
        { value: 0.5, text: '12h' },
        { value: 1, text: '1 d' },
        { value: 2, text: '2 d' },
        { value: 3, text: '3 d' },
        { value: 4, text: '4 d' },
        { value: 5, text: '5 d' },
        { value: 6, text: '6 d' },
        { value: 7, text: '7 d' },
        { value: 10, text: '10 d' },
        { value: 14, text: '14 d' },
        { value: 21, text: '21 d' },
        { value: 30, text: '30 d' },
      ],

      multipleOccupations: [1,2,3],
      allModulesSelected: true,
      selectedModules: [],
      multipleOccupationsByModule: [],
      
      moduleIndex: -1,

      geneticPatterns: [
        { value: 'ZEBUINO', text: 'Zebuíno' },
        { value: 'CRUZAMENTO_INDUSTRIAL', text: 'Cruzamento industrial' },
        { value: 'CRUZAMENTO_LEITEIRO', text: 'Cruzado leiteiro' }
      ],

      categoriesAnimal: [
        { value: 'MACHO_12', text: 'Macho até 12m' },
        { value: 'MACHO_13-24', text: 'Macho 13-24m' },
        { value: 'MACHO_+24', text: 'Macho +24m' },
        { value: 'FEMEA_12', text: 'Fêmea até 12m' },
        { value: 'FEMEA_13-24', text: 'Fêmea 13-24m' },
        { value: 'FEMEA_+24', text: 'Fêmea +24m' },
      ],

      categoriesComplementary: [
        { value: 'FEMEA_13-24_DESAFIO', text: 'Novilha desafio', category: 'FEMEA_13-24' },
        { value: 'FEMEA_13-24_PRENHA', text: 'Novilha prenha', category: 'FEMEA_13-24' },
        { value: 'FEMEA_13-24_DESCARTE', text: 'Novilha engorda', category: 'FEMEA_13-24' },
        { value: 'FEMEA_13-24_RECRIA', text: 'Novilha recria', category: 'FEMEA_13-24' },
        { value: 'FEMEA_+24_NOVILHA_PRENHA', text: 'Novilha prenha', category: 'FEMEA_+24' },
        { value: 'FEMEA_+24_NOVILHA_VAZIA', text: 'Novilha vazia', category: 'FEMEA_+24' },
        { value: 'FEMEA_+24_NOVILHA_DESCARTE', text: 'Novilha engorda', category: 'FEMEA_+24' },
        { value: 'FEMEA_+24_PARIDA_PRENHA', text: 'Vaca parida prenha', category: 'FEMEA_+24' },
        { value: 'FEMEA_+24_PARIDA_VAZIA', text: 'Vaca parida vazia', category: 'FEMEA_+24' },
        { value: 'FEMEA_+24_SOLTEIRA_PRENHA', text: 'Vaca solteira prenha', category: 'FEMEA_+24' },
        { value: 'FEMEA_+24_SOLTEIRA_VAZIA', text: 'Vaca solteira vazia', category: 'FEMEA_+24' },
        { value: 'FEMEA_+24_DESCARTE', text: 'Vaca descarte', category: 'FEMEA_+24' },
      ],

      headerModuleDataAndAdjustedOffer: [
        { text: 'label', value: 'label', align: 'right' },
        { text: 'value', value: 'value', align: 'right' },
      ],

      headerResults: [
        { text: 'label', value: 'label', align: 'right' },
        { text: 'value', value: 'value', align: 'right' },
      ],

      multipleWeight: [
        { label: "Garote de Recria", value: 270, category: 'MACHO_13-24', categoryComplementary: null },
        { label: "Novilha de Recria", value: 240, category: 'FEMEA_13-24', categoryComplementary: 'FEMEA_13-24_RECRIA' },
        { label: "Boi de Engorda", value: 500, category: 'MACHO_+24', categoryComplementary: null },
        { label: "Vaca Parideira", value: 450, category: 'FEMEA_+24', categoryComplementary: 'FEMEA_+24_PARIDA_PRENHA' }
      ],

      configModuleDataAndAdjustedOffer: {
        moduleArea: { label: 'Área do módulo (ha)', color: 'brown darken-2 white--text', position: 1 },
        qtdPaddocks: { label: 'Quantidade de piquetes', color: 'brown darken-2 white--text', position: 2 },
        availableDryMatter: { label: 'Matéria seca disponível (kg/piquete)', color: 'yellow darken-3 white--text', position: 3 },
        crudeProtein: { label: 'Proteína bruta', color: 'yellow darken-3 white--text', position: 4 },
        fiberInNeutralDetergent: { label: 'Fibra em detergente neutro', color: 'yellow darken-3 white--text', position: 5 },
        metabolizableEnergy: { label: 'Energia Metabolizável (Mcal/kg)', color: 'yellow darken-3 white--text', position: 6 },
      },

      configResults: {
        averageBatchWeight: { label: 'Peso médio do lote', hidden: true, position: 1 },
        numberOfHeads: { label: 'Cabeças', hidden: true, position: 2 },
        expectedPerformance: { label: 'Desempenho previsto (g/cabeça/dia)', hidden: true, position: 3 },
        expectedConsumption: { label: 'Consumo esperado de pasto (kg MS/cab/dia)', position: 4 },
        consumptionByLiveWeight: { label: 'Consumo / Peso vivo', position: 5 },
        moduleCapacity: { label: 'Lotação do módulo (UA/ha)', position: 6 },
        instantPicketCharge: { label: 'Carga Instantânea do piquete (UA/ha)', position: 7 },
        restTime: { label: 'Tempo de descanso (dias)', position: 8 },
        arrobasProduced: { label: 'Arrobas produzidas/hectare/mês', position: 9 },
      },

      configMultipleResults: {
        averageBatchWeight: { label: 'Peso médio do lote', hidden: true, position: 1 },
        numberOfHeads: { label: 'Cabeças', position: 2 },
        expectedPerformance: { label: 'Desempenho previsto (g/cabeça/dia)', position: 3 },
        expectedConsumption: { label: 'Consumo esperado de pasto (kg MS/cab/dia)', position: 4 },
        consumptionByLiveWeight: { label: 'Consumo / Peso vivo', position: 5 },
        moduleCapacity: { label: 'Lotação do módulo (UA/ha)', position: 6 },
        instantPicketCharge: { label: 'Carga Instantânea do piquete (UA/ha)', position: 7 },
        restTime: { label: 'Tempo de descanso (dias)', position: 8 },
        arrobasProduced: { label: 'Arrobas produzidas/hectare/mês', position: 9 },
      },

      moduleDataAndAdjustedOffer: [],
      results: [],
      multipleResults: [],
      resultsRaw: { results: {} },
      categoriesComplementaryFiltred: [],

      validations: {
        farm: [(v) => !!v || 'Fazenda é obrigatório'],
        module: [(v) => !!v || 'Módulo é obrigatório'],
        calc_type: [(v) => !!v || 'Tipo de cálculo é obrigatório'],
        day_occupation: [(v) => !!v || 'Ocupação é obrigatório'],
        genetic_pattern: [(v) => !!v || 'Padrão genético é obrigatório'],
        category_animal: [(v) => !!v || 'Categoria animal é obrigatório'],
        category_complementary: [(v) => (this.categoriesComplementaryFiltred.length === 0 || !!v) || 'Categoria complementar é obrigatório'],
        average_batch_weight: [(v) => !!v || 'Peso médio do lote é obrigatório'],
        supplement_mineral_consumption: [(v) => {
          if (v && this.form.average_batch_weight) {
            const consumeMS = this.form.supplement_mineral_consumption * this.params['MINERAL_MS_PERC']
            const percent = (consumeMS / this.form.average_batch_weight) * 100
            return percent <= this.params['SUPPLEMENT_MS_MINERAL_LIMIT'] || 'Qtd. de MS excedeu o limite'
          }
          return true
        }],

        supplement_feed_consumption: [(v) => {
          if (v && this.form.average_batch_weight) {
            const consumeMS = this.form.supplement_feed_consumption * this.params['FEED_MS_PERC']
            const percent = (consumeMS / this.form.average_batch_weight) * 100
            return percent <= this.params['SUPPLEMENT_MS_FEED_LIMIT'] || 'Qtd. de MS excedeu o limite'
          }
          return true
        }],

        supplement_vol_ms: [(v) => {
          if (v && this.form.average_batch_weight) {
            const consumeMS = this.form.supplement_vol_consumption * (v / 100)
            const percent = (consumeMS / this.form.average_batch_weight) * 100
            return percent <= this.params['SUPPLEMENT_MS_VOL_LIMIT'] || 'Qtd. de MS excedeu o limite'
          }
          return true
        }],

        supplement_enabled: [(v) => {
          if (v && this.form.average_batch_weight) {
            let consumeMS = 0
            if (this.form.supplement_mineral_consumption) {
              consumeMS += this.form.supplement_mineral_consumption * this.params['MINERAL_MS_PERC']
            }
            if (this.form.supplement_feed_consumption) {
              consumeMS += this.form.supplement_feed_consumption * this.params['FEED_MS_PERC']
            }
            if (this.form.supplement_vol_consumption && this.form.supplement_vol_ms) {
              consumeMS += this.form.supplement_vol_consumption * this.form.supplement_vol_ms / 100
            }
            const percent = (consumeMS / this.form.average_batch_weight) * 100
            return percent <= this.params['SUPPLEMENT_MS_TOTAL_LIMIT'] || 'Consumo de matéria seca excedeu o limite do peso do animal.'
          }
          return true
        }],
      }
    }
  },

  computed: {
    ...mapGetters('auth', ['isManager','user']),

    supplementConsumption () {
      if (this.form.supplement_enabled) {
        return toNumber(this.form.supplement_mineral_consumption || 0) + toNumber(this.form.supplement_feed_consumption || 0) + toNumber(this.form.supplement_vol_consumption || 0)
      }
      return null
    },

    alertExpectedPerformance () {
      return this.form.calc_type == 'NUMBER_OF_HEADS_PER_PERFORMANCE_GOAL' && this.resultsRaw.results.maxPerformance < this.form.expected_performance
    },

    resultTitle () {
      const item = this.calcs.find(item => item.value === this.form.calc_type)
      if (item) return item.text
      return ''
    },

    farmSelected () {
      const farm = this.farms.find(item => item.id === this.form.farm_id)
      if (!farm) null
      return farm
    },

    moduleSelected () {
      const module = this.modules.find(item => item.value === this.form.module_id)
      if (!module) null
      return module
    },

    daysOccupationsSelected () {
      const day_occupation = this.daysOccupations.find(item => item.value === this.form.day_occupation)
      if (!day_occupation) null
      return day_occupation
    },

    geneticPatternsSelected () {
      const geneticPattern = this.geneticPatterns.find(item => item.value === this.form.genetic_pattern)
      if (!geneticPattern) null
      return geneticPattern
    },

    categoriesAnimalSelected () {
      const categoriesAnimal = this.categoriesAnimal.find(item => item.value === this.form.category_animal)
      if (!categoriesAnimal) null
      return categoriesAnimal
    },

    categoriesComplementarySelected () {
      const categoryComplementary = this.categoriesComplementary.find(item => item.value === this.form.category_complementary)
      if (!categoryComplementary) null
      return categoryComplementary
    },

    animalLabel () {
      if (this.categoriesComplementaryFiltred.length > 0) {
        return `${this.geneticPatternsSelected && this.geneticPatternsSelected.text} / ${this.categoriesAnimalSelected && this.categoriesAnimalSelected.text} / ${this.categoriesComplementarySelected && this.categoriesComplementarySelected.text}`
      }
      return `${this.geneticPatternsSelected && this.geneticPatternsSelected.text} / ${this.categoriesAnimalSelected && this.categoriesAnimalSelected.text}`
    },

    monthLabel () {
      const monthNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
      const arrDate = this.reference_month.split('-')
      const year = arrDate[0]
      const month = Number(arrDate[1])
      return `${monthNames[month - 1]}/${year}`
    }
  },

  watch: {
    'form.farm_id': function () {
      this.batch = {}
      //this.form.module_id = this.$route.query.module_id;
    },

    'batch.id': function() {
      this.loadingBatch = true;
      if (typeof this.batch.average_weight !== 'undefined') this.form.average_batch_weight = this.batch.average_weight.toFixed(1)
      if (typeof this.batch.number_of_heads !== 'undefined') this.form.number_of_heads = this.batch.number_of_heads
      if (typeof this.batch.category_animal !== 'undefined') this.form.category_animal = this.batch.category_animal
      this.showCategoryComplementary()
      if (typeof this.batch.category_complementary !== 'undefined') this.form.category_complementary = this.batch.category_complementary
      if (typeof this.batch.genetic_pattern !== 'undefined') this.form.genetic_pattern = this.batch.genetic_pattern;
      if (typeof this.batch.day_occupation !== 'undefined') this.form.day_occupation = this.batch.day_occupation;
      if (this.batch.supplement_mineral_consumption) this.form.supplement_mineral_consumption = this.batch.supplement_mineral_consumption;
      if (this.batch.supplement_mineral_pb) this.form.supplement_mineral_pb = this.batch.supplement_mineral_pb;
      if (this.batch.supplement_mineral_ndt) this.form.supplement_mineral_ndt = this.batch.supplement_mineral_ndt;
      if (this.batch.supplement_feed_consumption) this.form.supplement_feed_consumption = this.batch.supplement_feed_consumption;
      if (this.batch.supplement_feed_pb) this.form.supplement_feed_pb = this.batch.supplement_feed_pb;
      if (this.batch.supplement_feed_ndt) this.form.supplement_feed_ndt = this.batch.supplement_feed_ndt;
      if (this.batch.supplement_feed_fdn) this.form.supplement_feed_fdn = this.batch.supplement_feed_fdn;
      if (this.batch.supplement_vol_consumption) this.form.supplement_vol_consumption = this.batch.supplement_vol_consumption;
      if (this.batch.supplement_vol_ms) this.form.supplement_vol_ms = this.batch.supplement_vol_ms;
      if (this.batch.supplement_vol_pb) this.form.supplement_vol_pb = this.batch.supplement_vol_pb;
      if (this.batch.supplement_vol_em) this.form.supplement_vol_em = this.batch.supplement_vol_em;
      if (this.batch.supplement_vol_fdn) this.form.supplement_vol_fdn = this.batch.supplement_vol_fdn;
      this.loadingBatch = false;
    }
  },

  created () {
    this.init()
  },

  methods:{
    async init() {
      this.form.module_id = this.$route.query.module_id
      this.fetchParams()
      await this.fetchFarms()
      if (this.farms[0]) {
        this.lastFarm = localStorage.getItem('lastFarm')
        this.lastModule = localStorage.getItem('lastModule')
        if(this.lastFarm) {
          this.$set(this.form, 'farm_id', this.$route.query.farm_id || this.lastFarm)
          await this.fetchModules()
          if (this.$route.query.module_id) {
            this.$set(this.form, 'module_id', this.$route.query.module_id)
            await this.fetchBatches()
          } else {
              this.$set(this.form, 'module_id', this.lastModule)
              this.handleModuleChange(this.lastModule)
              if (this.lastModule !== 'Todos') {
                await this.fetchBatches()
              }
          }
          if (this.$route.query.module_id) {
            this.$set(this.form, 'supplement_enabled', this.batch.supplement_enabled)
          }
        }
      }
    },

    setLastFarm() {
      if(this.farms[0]) this.lastFarm = localStorage.setItem('lastFarm', this.form.farm_id)
    },

    setLastModule() {
      if(this.modules[0]) this.lastModules = localStorage.setItem('lastModule', this.form.module_id)
    },

    checkUser() {
      if (!this.isManager) {
        if (this.form && this.form.farm_id) {
          let farm = {}
          for (let index = 0; index < this.farms.length; index++ ) {
            if (this.farms[index].id === this.form.farm_id) {
              farm = this.farms[index]
              break
            }
          }
          if (farm.desactivated) {
            return true
          } else {
            return false
          }
        }
      } else {
        return false
      }
    },

    changeSupplementEnabled () {
      if (!this.form.supplement_enabled) {
        this.form.supplement_mineral_consumption = null
        this.form.supplement_mineral_pb = null
        this.form.supplement_mineral_ndt = null
        this.form.supplement_feed_consumption = null
        this.form.supplement_feed_pb = null
        this.form.supplement_feed_ndt = null
        this.form.supplement_feed_fdn = null
        this.form.supplement_vol_consumption = null
        this.form.supplement_vol_ms = null
        this.form.supplement_vol_pb = null
        this.form.supplement_vol_em = null
        this.form.supplement_vol_fdn = null
      }
    },
  
    async calc(){
      if (!this.$refs.form.validate()) return false;
      this.loadingCalc = true
      try {
        const form = cloneDeep(this.form)
        form.reference_month = this.reference_month
        form.number_of_heads = toNumber(form.number_of_heads)
        form.average_batch_weight = toNumber(form.average_batch_weight)
        form.expected_performance = toNumber(form.expected_performance)
        form.supplement_mineral_consumption = toNumber(form.supplement_mineral_consumption)
        form.supplement_mineral_pb = toNumber(form.supplement_mineral_pb)
        form.supplement_mineral_ndt = toNumber(form.supplement_mineral_ndt)
        form.supplement_feed_consumption = toNumber(form.supplement_feed_consumption)
        form.supplement_feed_pb = toNumber(form.supplement_feed_pb)
        form.supplement_feed_ndt = toNumber(form.supplement_feed_ndt)
        form.supplement_feed_fdn = toNumber(form.supplement_feed_fdn)
        form.supplement_vol_consumption = toNumber(form.supplement_vol_consumption)
        form.supplement_vol_ms = toNumber(form.supplement_vol_ms)
        form.supplement_vol_pb = toNumber(form.supplement_vol_pb)
        form.supplement_vol_em = toNumber(form.supplement_vol_em)
        form.supplement_vol_fdn = toNumber(form.supplement_vol_fdn)
        form.calculator = true
        const res = await this.$apollo.query({
          query: gql`
            query calc($data: CalcInput!) {
              calc(
                data: $data
              ) {
                result
              }
            }
          `,
          variables: {
            data: form
          },
          fetchPolicy: 'no-cache',
        });
        this.resultsRaw = res.data.calc.result
        this.results = Object.entries(res.data.calc.result.results)
        .map(([name, value]) => {
          return {
            name,
            value,
            ...this.processResults(name, value)
          }
        })
        .filter(item => item.label && !item.hidden)
        .sort((a, b) => a.position > b.position ? 1 : -1);
        this.$store.dispatch('analytics/trackEvent', { event: 'Calculate', params: form })
        this.moduleDataAndAdjustedOffer = Object.entries(res.data.calc.result.moduleDataAndAdjustedOffer)
        .map(([name, value]) => {
          return {
            name,
            value,
            ...this.configModuleDataAndAdjustedOffer[name]
          }
        })
        .filter(item => item.label && !item.hidden)
        .sort((a, b) => a.position > b.position ? 1 : -1);

      } catch (e) {
        const graphQLError = e?.graphQLErrors[0]
        if(graphQLError?.extensions?.code === 'business') {
          Swal.fire({ icon: 'error', text: graphQLError?.message });
        } else {
          console.error(e);
        }
      }

      this.loadingCalc = false
    },

    async calcAndExport() {
      this.loadingCalc = true
      try {
        await this.multipleCalc()
        await this.exportToCsv()
      } catch (e) {
        let graphQLError
        if (e && e.graphQLErrors) {
          graphQLError = e.graphQLErrors[0]
        } else {
          graphQLError = e
        }
        if(graphQLError?.extensions?.code === 'business') {
          Swal.fire({ icon: 'error', text: graphQLError?.message });
        } else {
          console.error('erro : ', e);
        }
      } finally {
        this.loadingCalc = false
      }
    },

    async multipleCalc() {
  this.multipleResults = [];

  for (let index = 0; index < this.multipleOccupationsByModule.length; index++) {
    const module = this.multipleOccupationsByModule[index];
    if (module.selected) {
      this.multipleResults.push({});
      this.multipleResults[this.multipleResults.length - 1].moduleName = module.moduleName;

      // Usando for...of para o loop assíncrono
      for (const day of module.occupations) {
        this.multipleResults[this.multipleResults.length - 1][day] = {};

        // Usando for...of para o loop assíncrono em multipleWeight
        for (const weight of this.multipleWeight) {
          try {
            const form = cloneDeep(this.form);
            form.reference_month = this.reference_month;
            form.module_id = module.moduleId;
            form.number_of_heads = toNumber(form.number_of_heads);
            form.day_occupation = toNumber(day);
            form.calc_type = 'NUMBER_OF_HEADS_THAT_FIT_IN_MODULE';
            form.genetic_pattern = 'ZEBUINO';
            form.category_animal = weight.category;
            form.category_complementary = weight.categoryComplementary;
            form.average_batch_weight = toNumber(weight.value);
            form.expected_performance = toNumber(form.expected_performance);
            form.supplement_mineral_consumption = toNumber(form.supplement_mineral_consumption);
            form.supplement_mineral_pb = toNumber(form.supplement_mineral_pb);
            form.supplement_mineral_ndt = toNumber(form.supplement_mineral_ndt);
            form.supplement_feed_consumption = toNumber(form.supplement_feed_consumption);
            form.supplement_feed_pb = toNumber(form.supplement_feed_pb);
            form.supplement_feed_ndt = toNumber(form.supplement_feed_ndt);
            form.supplement_feed_fdn = toNumber(form.supplement_feed_fdn);
            form.supplement_vol_consumption = toNumber(form.supplement_vol_consumption);
            form.supplement_vol_ms = toNumber(form.supplement_vol_ms);
            form.supplement_vol_pb = toNumber(form.supplement_vol_pb);
            form.supplement_vol_em = toNumber(form.supplement_vol_em);
            form.supplement_vol_fdn = toNumber(form.supplement_vol_fdn);
            form.calculator = true;

            const res = await this.$apollo.query({
              query: gql`
                query calc($data: CalcInput!) {
                  calc(data: $data) {
                    result
                  }
                }
              `,
              variables: {
                data: form
              },
              fetchPolicy: 'no-cache',
            });

            this.resultsRaw = res.data.calc.result;
            const results = Object.entries(res.data.calc.result.results)
              .map(([name, value]) => {
                return {
                  name,
                  value,
                  ...this.processMultipleResults(name, value)
                };
              })
              .filter(item => item.label && !item.hidden)
              .sort((a, b) => a.position > b.position ? 1 : -1);

            // Atualizando multipleResults com os resultados
            this.multipleResults[this.multipleResults.length - 1][day][weight.label] = {};
            this.multipleResults[this.multipleResults.length - 1][day][weight.label].weight = weight.value;
            this.multipleResults[this.multipleResults.length - 1][day][weight.label].calcs = results;

          } catch (e) {
            let graphQLError
            if (e && e.graphQLErrors) {
              graphQLError = e.graphQLErrors[0]
            } else {
              graphQLError = e
            }
            if(graphQLError?.extensions?.code === 'business') {
              Swal.fire({ icon: 'error', text: graphQLError?.message + ' no módulo : ' + module.moduleName });
              break
            } else {
              console.error(e);
            }
          }
        }
      }
    }
  }
},

    exportToCsv() {
      var CsvString = "";
      const infoByCategory = []
      for (const module of this.multipleResults) {
        let days = Object.keys(module)
        days = days.filter(key => key !== 'moduleName')
        const info = {}
        info.moduleName = module.moduleName
        CsvString += '\ufeff' + module.moduleName + ';'
        CsvString += "\r\n";
        CsvString += '\ufeff' + 'Se a ocupação do piquete for essa (dias)' + ';'
        days.forEach(function(day) { 
          CsvString += '\ufeff' + day + ';'
        })
        CsvString += "\r\n";
        CsvString += '\ufeff' + 'O tempo de descanso do pasto será (dias)' + ';'
        for (const day of days) {
          const calcDay = module[day];
          const categories = Object.keys(calcDay);
          const calcCategory = calcDay[categories[0]]; // Acessando o primeiro item de categorias corretamente
          const formattedValue = (calcCategory.calcs[6].value.toFixed(1)).replace('.', ',')
          CsvString += '\ufeff' + formattedValue + ';';
        }
        CsvString += "\r\n";
        CsvString += "\r\n";
        for (const day of days) {
          const calcDay = module[day]
          const categories = Object.keys(calcDay)
          for (const category of categories) {
            const categoryDay = calcDay[category]
            
            if (!info[category]) {
              info[category] = {}
              info[category].numberOfHeads = []
              info[category].expectedPerformance = []
              info[category].comsumption = []
              info[category].consumptionByLiveWeight = []
              info[category].moduleCapacity = []
              info[category].restTime = []
            }
            info[category].numberOfHeads.push(categoryDay.calcs[0].value)
            info[category].expectedPerformance.push(categoryDay.calcs[1].value)
            info[category].comsumption.push(categoryDay.calcs[2].value)
            info[category].consumptionByLiveWeight.push(categoryDay.calcs[3].value)
            info[category].moduleCapacity.push(categoryDay.calcs[4].value)
            info[category].restTime.push(categoryDay.calcs[6].value)
            info[category].weight = categoryDay.weight
          }

        }
        infoByCategory.push(info)
      }

      for (const module of infoByCategory) {
        const keysWithModule = Object.keys(module);
        const keys =  keysWithModule.filter(key => key !== 'moduleName');
        CsvString += '\ufeff' + module.moduleName + ';'
        CsvString += "\r\n";
        for (const key of keys) {
          const calc = module[key];
          // Adicionando informações ao CSV
          CsvString += '\ufeff' + key + ': ' + calc.weight + ' kg de peso vivo';
          CsvString += "\r\n";
          
          CsvString += '\ufeff' + 'Consumo de pasto kg de (MS/cab/dia)' + ';';
          for (const value of calc.comsumption) {
            const formattedValue = (value.toFixed(1)).replace('.', ',');
            CsvString += '\ufeff' + formattedValue + ';';
          }
          CsvString += "\r\n";
          
          CsvString += '\ufeff' + 'Relação de consumo x peso vivo(%)' + ';';
          for (const value of calc.consumptionByLiveWeight) {
            const formattedValue = (value.toFixed(1)).replace('.', ',');
            CsvString += '\ufeff' + formattedValue + ';';
          }
          CsvString += "\r\n";
          
          CsvString += '\ufeff' + 'Capacidade de Suporte (cabeças)' + ';';
          for (const value of calc.numberOfHeads) {
            const formattedValue = (value.toFixed(0)).replace('.', ',');
            CsvString += '\ufeff' + formattedValue + ';';
          }
          CsvString += "\r\n";
          
          CsvString += '\ufeff' + 'Desempenho individual (g/cab/dia)' + ';';
          for (const value of calc.expectedPerformance) {
            const min = (value * 0.95).toFixed(0).replace('.', ',');
            const max = (value * 1.05).toFixed(0).replace('.', ',');
            CsvString += '\ufeff' + min + ' - ' + max + ';';
          }
          CsvString += "\r\n\r\n";
        }
      }
      CsvString = "data:application/csv," + encodeURIComponent(CsvString);
      
      var x = document.createElement("A");
      x.setAttribute("href", CsvString );
      x.setAttribute("download","calc-report.csv");
      document.body.appendChild(x);
      x.click();
    },

    processResults(name, value) {
      let config = this.configResults[name]
      let warning = null
      if (name === 'moduleCapacity') {
        if (value > 5) {
          warning = 'Lotações excessivas podem prejudicar o pasto!'
        }
      } else if (name === 'restTime') {
        if (value < 30) {
          warning = 'Tempo de descanso curto, verifique se o tempo de ocupação está correto.'
        }
      }
      return {
        ...config,
        warning
      }
    },

    processMultipleResults(name, value) {
      let config = this.configMultipleResults[name]
      let warning = null
      if (name === 'moduleCapacity') {
        if (value > 5) {
          warning = 'Lotações excessivas podem prejudicar o pasto!'
        }
      } else if (name === 'restTime') {
        if (value < 30) {
          warning = 'Tempo de descanso curto, verifique se o tempo de ocupação está correto.'
        }
      }
      return {
        ...config,
        warning
      }
    },

    async fetchParams() {
      try {
        const res = await this.$apollo.query({
          query: gql`
            query Params {
              params {
                id
                info
                value
              }
            }
          `,
          fetchPolicy: 'no-cache',
        });
        const resultParams = res.data
        // Parametros ajustados
        const configParams = resultParams.params.reduce((acc, item) => {
          // return acc[item.id] = item.value
          return {...acc, [item.id]: item.value}
        }, {})
        this.params = configParams
      } catch (e) {
        console.error(e);
      }
    },

    async fetchFarms() {
      this.loadingFarms = true
      try {
        let result
        if (this.isManager) {
          result = await this.$apollo.query({
            query: gql`
              query farms {
                farms(
                  where: {
                    desactivated: {_is_null: true}
                  },
                  order_by: [{ name: asc }]
                ) {
                  id
                  name
                  desactivated
                  customer{
                    id
                    name
                  }
                }
              }
            `,
            fetchPolicy: 'no-cache',
          });
          let desactivatedFarms = await this.$apollo.query({
            query: gql`
              query farms {
                farms(
                  where: {
                    desactivated: {_eq: true}
                  },
                  order_by: [{ name: asc }]
                ) {
                  id
                  name
                  desactivated
                  customer{
                    id
                    name
                  }
                }
              }
            `,
            fetchPolicy: 'no-cache',
          });
          if (desactivatedFarms && desactivatedFarms.data && desactivatedFarms.data.farms) {
            result.data.farms = [...result.data.farms, ...desactivatedFarms.data.farms]
          }
        } else {
          result = await this.$apollo.query({
            query: gql`
              query farms {
                farms(
                  order_by: [{ name: asc }]
                ) {
                  id
                  name
                  desactivated
                  customer{
                    id
                    name
                  }
                }
              }
            `,
            fetchPolicy: 'no-cache',
          });
        }
        this.loadingFarms = false
        this.farms = result.data.farms.map(item => ({value: item.id, text: item.name, ...item}));
      } catch (e) {
        console.error(e);
      }
      this.loadingFarms = false
    },

    async fetchModules() {
      this.loadingModules = true
      try {
        const result = await this.$apollo.query({
          query: gql`
            query modules ($farmId: uuid!) {
              modules(
                where: {
                  farm_id: { _eq: $farmId }
                },
                order_by: [{ name: asc }]
              ) {
                id
                name
              }
            }
          `,
          variables: {
            farmId: this.form.farm_id
          },
          fetchPolicy: 'no-cache',
        });
        this.loadingModules = false
        this.modules = result.data.modules.map(item => ({value: item.id, text: item.name}));
        this.multipleOccupationsByModule = this.modules.map((module) => ({
          moduleName: module.text,
          moduleId: module.value,
          selected: true,
          occupations: [...this.multipleOccupations]
        }));
        this.selectedModules = this.multipleOccupationsByModule.map(module => {
        return {
          value: module.moduleId,  
          text: module.moduleName 
        };
      });
      } catch (e) {
        console.error(e);
      }
      this.loadingModules = false
    },

    async fetchBatches() {
      this.loadingBatch = true;
      if (this.form.module_id) {
        try {
          const result = await this.$apollo.query({
            query: gql`
              query MyQuery($moduleId: uuid!) {
                batches(
                  where: {
                    module_id: {_eq: $moduleId},
                    status: {_eq: "ATIVO"}
                  }
                ) {
                id,
                day_occupation,
                supplement_mineral_consumption
                supplement_mineral_pb
                supplement_mineral_ndt 
                supplement_feed_consumption
                supplement_feed_pb
                supplement_feed_ndt
                supplement_feed_fdn
                supplement_vol_consumption
                supplement_vol_ms
                supplement_vol_pb
                supplement_vol_em
                supplement_vol_fdn
                supplement_enabled
                genetic_pattern,
                category_animal,
                category_complementary,
                average_weight,
                number_of_heads
                }
              }
            `,
            variables: {
              moduleId: this.form.module_id
            },
            fetchPolicy: 'no-cache',
          });
          if (result.data.batches[0]) this.batch = result.data.batches[0]
          else {
            this.batch = {};
          }
          this.loadingBatch = false;
        } catch (e) {
          console.error(e);
        }
      }
    },

    farmFilter (item, queryText) {
      const textOne = item?.name?.toLowerCase()
      const textTwo = item?.customer?.name?.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
    },

    isVisible(field) {
      if(this.form.calc_type === 'WHAT_IS_THE_EXPECTED_WEIGHT_GAIN_IN_THIS_BATCH'){
        if (field === 'number_of_heads') return true
        if (field === 'average_batch_weight') return true
        if (field === 'category_animal') return true
      }
      if(this.form.calc_type === 'NUMBER_OF_HEADS_THAT_FIT_IN_MODULE'){
        if (field === 'average_batch_weight') return true
        if (field === 'category_animal') return true
      }
      if(this.form.calc_type === 'NUMBER_OF_HEADS_PER_PERFORMANCE_GOAL'){
        if (field === 'expected_performance') return true
        if (field === 'average_batch_weight') return true
        if (field === 'category_animal') return true
      }
      return false
    },

    showCategoryComplementary() {
      this.categoriesComplementaryFiltred = this.categoriesComplementary.filter((item) => {
        return item.category === this.form.category_animal
      })
      if (!this.categoriesComplementaryFiltred.lenght) {
        this.$set(this.form, 'category_complementary', null)
      }
    },

    openModalResults(){
      this.$refs.modalResults.$emit('open')
    },

    print() {
      window.print()
    },

    applyOccupations() {
      this.selectedModules = this.multipleOccupationsByModule.map(module => {
        return {
          value: module.moduleId,  
          text: module.moduleName 
        };
      });
      this.dialogOccupation = false
    },

    handleModuleChange(id) {
      const selectedModule = this.modules.find(module => module.value === id);
      if (selectedModule) {
        this.moduleIndex = this.modules.indexOf(selectedModule);
      }
    },

    toggleOccupation() {
      this.multipleOccupationsByModule.forEach((module) => {
        // Filtra as ocupações, removendo as que não estão em multipleOccupations
        module.occupations = module.occupations.filter(occupation =>
          this.multipleOccupations.includes(occupation)
        );

        // Adiciona ocupações que estão em multipleOccupations, mas não estão em module.occupations
        this.multipleOccupations.forEach((occupation) => {
          if (!module.occupations.includes(occupation)) {
            module.occupations.push(occupation);
          }
        });
      });
    },

    toggleAllModules() {
      this.multipleOccupationsByModule.forEach(module => {
        module.selected = this.allModulesSelected;
      });
    }
  }
}
</script>

<template>
  <div>
    <!-- <h1 class="mb-5">Calculadora</h1> -->

    <v-row>
      <v-col cols="12"
        sm="12"
        md="4">
        <h2 class="mb-0 text--secondary">Calcular índices</h2>
        <p class="text--secondary"><i>Informe os dados abaixo</i></p>


        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="calc">
          <v-card>
            <v-card-title>
              <span class="subtitle-2">Calculadora</span>
              <v-switch v-if="isManager" v-model="enableMultiple" class="ml-2" label="Múltipla"></v-switch>
              <v-spacer></v-spacer>
              <span class="subtitle-2 text--secondary">{{ monthLabel }}</span>
              <v-dialog
                ref="dialog"
                v-model="modalDateRef"
                :return-value.sync="reference_month"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    attrs="attrs"
                    icon
                    class="mr-2"
                    v-on="on"
                  >
                    <v-icon>mdi-calendar-today</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="reference_month"
                  type="month"
                  color="success"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="modalDateRef = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(reference_month)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-card-title>
            <v-card-text>
              <v-row v-if="!enableMultiple">
                <v-col cols="12" sm="12" class="pb-0">
                  <v-autocomplete
                    v-model="form.farm_id"
                    :items="farms"
                    :loading="loadingFarms"
                    :rules="validations.farm"
                    :filter="farmFilter"
                    label="Fazenda"
                    dense
                    outlined
                    persistent-placeholder
                    @input="fetchModules"
                    @change="setLastFarm()"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title v-if="!data.item.desactivated" v-html="data.item.name"></v-list-item-title>
                        <v-list-item-title v-if="data.item.desactivated" v-html="data.item.name + ' (inativa)'"></v-list-item-title>
                        <v-list-item-subtitle v-if="data.item.customer" v-html="data.item.customer.name"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pb-0">
                  <v-autocomplete
                    v-model="form.module_id"
                    :disabled="!form.farm_id"
                    :items="modules"
                    :loading="loadingModules"
                    :rules="validations.modules"
                    label="Módulo"
                    dense
                    outlined
                    persistent-placeholder
                    @change="() => { fetchBatches(); setLastModule(); handleModuleChange(form.module_id); }"
                  />
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pb-0">
                  <v-autocomplete
                    v-model="form.day_occupation"
                    :items="daysOccupations"
                    :loading="loadingBatch"
                    :rules="validations.day_occupation"
                    label="Ocupação"
                    dense
                    outlined
                    persistent-placeholder
                  />
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pb-0">
                  <v-autocomplete
                    v-model="form.genetic_pattern"
                    :items="geneticPatterns"
                    :loading="loadingBatch"
                    :rules="validations.genetic_pattern"
                    label="Padrão genético"
                    dense
                    outlined
                    persistent-placeholder
                  />
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pb-0">
                  <v-autocomplete
                    v-model="form.category_animal"
                    :items="categoriesAnimal" 
                    :loading="loadingBatch"
                    :rules="validations.category_animal"
                    label="Categoria animal"
                    dense
                    outlined
                    persistent-placeholder
                    @change="showCategoryComplementary()"
                  />
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pb-0">
                  <v-text-field
                    v-model="form.average_batch_weight"
                    :loading="loadingBatch"
                    :rules="validations.average_batch_weight"
                    label="Peso médio do lote"
                    suffix="kg"
                    type="number"
                    class="text-field-right"
                    dense
                    outlined
                    persistent-placeholder
                  />
                </v-col>
                <v-col v-show="categoriesComplementaryFiltred.length" cols="12" md="6" sm="12" class="pb-0">
                  <v-autocomplete
                    v-model="form.category_complementary"
                    :items="categoriesComplementaryFiltred"
                    :rules="validations.category_complementary"
                    label="Categoria complementar"
                    dense
                    outlined
                    persistent-placeholder
                  />
                </v-col>
                <v-col cols="12" sm="12" class="pb-0">
                  <v-autocomplete
                    v-model="form.calc_type"
                    :items="calcs"
                    :rules="validations.calc_type"
                    label="O que deseja calcular?"
                    dense
                    outlined
                    persistent-placeholder
                    @change="() => result = false"
                  />
                </v-col>
                <v-col v-if="isVisible('number_of_heads')" sm="6" class="pb-0">
                  <v-text-field
                    v-model="form.number_of_heads"
                    label="Quantidade de cabeças"
                    type="number"
                    class="text-field-right"
                    dense
                    outlined
                    persistent-placeholder
                  />
                </v-col>
                <v-col v-if="isVisible('expected_performance')" sm="6" class="pb-0">
                  <v-text-field
                    v-model="form.expected_performance"
                    label="Desempenho esperado"
                    suffix="g/cab/dia"
                    type="number"
                    class="text-field-right"
                    dense
                    outlined
                    persistent-placeholder
                  />
                </v-col>
                <v-col sm='12' class="pb-0 pt-0">
                  <v-checkbox
                    v-model="form.supplement_enabled"
                    :rules="validations.supplement_enabled"
                    label='Suplementação por cabeça/dia'
                    class='pt-0'
                    dense
                    @change="changeSupplementEnabled()"
                  />
                  <v-expand-transition>
                    <div v-show="form.supplement_enabled">
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            Mineral proteinado e/ou energético
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-row>
                              <v-col sm='6'>
                                <v-text-field
                                  v-model="form.supplement_mineral_consumption"
                                  :rules="validations.supplement_mineral_consumption"
                                  label="Consumo"
                                  suffix="kg"
                                  hint='(MN/cab/dia)'
                                  type="number"
                                  class="text-field-right"
                                  dense
                                  persistent-placeholder
                                  persistent-hint
                                  outlined
                                />
                              </v-col>
                              <v-col sm='6'>
                                <v-text-field
                                  v-model="form.supplement_mineral_pb"
                                  label="Teor de PB"
                                  suffix="%"
                                  type="number"
                                  class="text-field-right"
                                  dense
                                  persistent-placeholder
                                  persistent-hint
                                  outlined
                                />
                              </v-col>
                              <v-col sm='12'>
                                <v-text-field
                                  v-model="form.supplement_mineral_ndt"
                                  label="Teor de NDT"
                                  hint='(será convertido em EM)'
                                  suffix="%"
                                  type="number"
                                  class="text-field-right"
                                  dense
                                  persistent-placeholder
                                  persistent-hint
                                  outlined
                                />
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            Ração concentrada
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-row>
                              <v-col sm='6'>
                                <v-text-field
                                  v-model="form.supplement_feed_consumption"
                                  :rules="validations.supplement_feed_consumption"
                                  label="Consumo"
                                  suffix="kg"
                                  hint='(MN/cab/dia)'
                                  type="number"
                                  class="text-field-right"
                                  dense
                                  persistent-placeholder
                                  persistent-hint
                                  outlined
                                />
                              </v-col>
                              <v-col sm='6'>
                                <v-text-field
                                  v-model="form.supplement_feed_pb"
                                  label="Teor de PB"
                                  suffix="%"
                                  type="number"
                                  class="text-field-right"
                                  dense
                                  persistent-placeholder
                                  persistent-hint
                                  outlined
                                />
                              </v-col>
                              <v-col sm='6'>
                                <v-text-field
                                  v-model="form.supplement_feed_ndt"
                                  label="Teor de NDT"
                                  hint='(será conv. em EM)'
                                  suffix="%"
                                  type="number"
                                  class="text-field-right"
                                  dense
                                  persistent-placeholder
                                  persistent-hint
                                  outlined
                                />
                              </v-col>
                              <v-col sm='6'>
                                <v-text-field
                                  v-model="form.supplement_feed_fdn"
                                  label="Teor de FDN"
                                  suffix="%"
                                  type="number"
                                  class="text-field-right"
                                  dense
                                  persistent-placeholder
                                  persistent-hint
                                  outlined
                                />
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            Volumoso
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-row>
                              <v-col sm='6'>
                                <v-text-field
                                  v-model="form.supplement_vol_consumption"
                                  label="Consumo"
                                  suffix="kg"
                                  hint='(MN/cab/dia)'
                                  type="number"
                                  class="text-field-right"
                                  dense
                                  persistent-placeholder
                                  persistent-hint
                                  outlined
                                />
                              </v-col>
                              <v-col sm='6'>
                                <v-text-field
                                  v-model="form.supplement_vol_ms"
                                  :rules="validations.supplement_vol_ms"
                                  label="Teor de MS"
                                  suffix="%"
                                  type="number"
                                  class="text-field-right"
                                  dense
                                  persistent-placeholder
                                  persistent-hint
                                  outlined
                                />
                              </v-col>
                              <v-col sm='6'>
                                <v-text-field
                                  v-model="form.supplement_vol_pb"
                                  label="Teor de PB"
                                  suffix="%"
                                  type="number"
                                  class="text-field-right"
                                  dense
                                  persistent-placeholder
                                  persistent-hint
                                  outlined
                                />
                              </v-col>
                              <v-col sm='6'>
                                <v-text-field
                                  v-model="form.supplement_vol_em"
                                  label="Teor de EM"
                                  suffix="Mcal/kg"
                                  type="number"
                                  class="text-field-right"
                                  dense
                                  persistent-placeholder
                                  persistent-hint
                                  outlined
                                />
                              </v-col>
                              <v-col sm='12'>
                                <v-text-field
                                  v-model="form.supplement_vol_fdn"
                                  label="Teor de FDN"
                                  suffix="%"
                                  type="number"
                                  class="text-field-right"
                                  dense
                                  persistent-placeholder
                                  persistent-hint
                                  outlined
                                />
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </div>
                  </v-expand-transition>
                </v-col>
                <v-col sm="12">
                  <v-btn
                    block
                    color="success"
                    :loading="loadingCalc"
                    :disabled="checkUser()"
                    @click="calc"
                  >
                    calcular
                  </v-btn>
               </v-col>
              </v-row>
              <v-row v-if="enableMultiple">
                <v-col cols="12" sm="12" class="pb-0">
                  <v-autocomplete
                    v-model="form.farm_id"
                    :items="farms"
                    :loading="loadingFarms"
                    :rules="validations.farm"
                    :filter="farmFilter"
                    label="Fazenda"
                    dense
                    outlined
                    persistent-placeholder
                    @input="fetchModules"
                    @change="setLastFarm()"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title v-if="!data.item.desactivated" v-html="data.item.name"></v-list-item-title>
                        <v-list-item-title v-if="data.item.desactivated" v-html="data.item.name + ' (inativa)'"></v-list-item-title>
                        <v-list-item-subtitle v-if="data.item.customer" v-html="data.item.customer.name"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-btn
                    block
                    color="#2196F3"
                    class="white--text"
                    @click="dialogOccupation = true"
                  >
                    Ocupação Geral
                  </v-btn>
                </v-col>
                
                <v-dialog v-model="dialogOccupation" max-width="none" persistent>
                  <v-card>
                    <v-card-title>
                    </v-card-title>

                    <v-card-text>
                      <!-- Tabela -->
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th class="text-left" style="vertical-align: middle; font-size: 18px;">Módulos</th>
                            <th class="text-left" style="vertical-align: middle; font-size: 18px;">Ocupações</th>
                          </tr>
                          <tr>
                            <th class="text-left" style="border-bottom: 2px solid #D3D3D3 ;">
                              <v-checkbox 
                                v-model="allModulesSelected"
                                :label="'Selecionar Todos'"
                                @change="toggleAllModules" 
                              />
                            </th>
                            <th style="border-bottom: 2px solid #D3D3D3 ;">
                              <v-row dense>
                                  <v-col
                                    v-for="(occupation, occupationIndex) in occupationList"
                                    :key="occupation.value"
                                    cols="12"
                                    sm="2"
                                    md="1"
                                  >
                                    <v-checkbox
                                      v-model="multipleOccupations"
                                      :label="occupation.text"
                                      :value="occupation.value"
                                      @change="toggleOccupation()"
                                    />
                                  </v-col>
                                </v-row>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <!-- Iterando sobre cada módulo -->
                          <tr v-for="(module, moduleIndex) in multipleOccupationsByModule" :key="moduleIndex">
                            <td>
                              <v-checkbox 
                                v-model="module.selected" 
                                :label="module.moduleName" 
                              />
                            </td>
                            <td>
                              <!-- Verificando se o módulo está selecionado -->
                              <v-row dense>
                                <v-col
                                  v-for="(occupation, occupationIndex) in occupationList"
                                  :key="occupation.value"
                                  cols="12"
                                  sm="2"
                                  md="1"
                                >
                                  <v-checkbox
                                    v-model="module.occupations"
                                    :label="occupation.text"
                                    class="checkbox-label-top"
                                    :value="occupation.value"
                                  />
                                </v-col>
                              </v-row>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card-text>

                    <v-card-actions class="justify-end">
                      <v-btn @click="dialogOccupation = false">Fechar</v-btn>
                      <v-btn color="success" @click="applyOccupations">Aplicar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-col v-for="(weight, index) in multipleWeight " :key="index" cols="12" md="6" sm="12" class="pb-0">
                  <v-text-field
                    v-model="weight.value"
                    :loading="loadingBatch"
                    :label="weight.label"
                    suffix="kg"
                    type="number"
                    class="text-field-right"
                    dense
                    outlined
                    persistent-placeholder
                  />
                </v-col>
                <v-col sm="12">
                  <v-btn
                    block
                    color="success"
                    :loading="loadingCalc"
                    @click="calcAndExport"
                  >
                    calcular
                  </v-btn>
               </v-col>

              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
      <v-col class="sm-8">
        <v-row>
          <v-col sm="12">
            <transition
              name="custom-classes-transition"
              enter-active-class="animate__animated animate__fadeInUp"
            >
              <div v-if="results.length" class="print print-focus">

                <div class="d-flex">
                  <div class="mr-auto">
                    <h2 class="mb-0 text--secondary">Resultado</h2>
                    <p class="text--secondary"><i>{{ resultTitle }}</i></p>
                  </div>
                  <div class="d-flex pt-4 no-print">
                    <v-btn fab dark small color="warning" class="mr-2" @click="openModalResults">
                      <v-icon dark>mdi-database </v-icon>
                    </v-btn>
                    <v-btn fab dark small color="primary" @click="print">
                      <v-icon dark>mdi-printer</v-icon>
                    </v-btn>
                  </div>
                </div>

                <v-alert
                  border="left"
                  colored-border
                  color="green darken-4"
                  elevation="1"
                  class="print-only"
                >
                  <v-row>
                    <v-col sm="4" class="py-0">
                      <span class="subtitle-2">
                        Informações
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="4">
                      <div class="body-1 d-flex justify-content-center align-items-center">
                        <v-icon class="title pr-2">mdi-account</v-icon> {{ farmSelected && farmSelected.customer.name | defaultEmpty }}
                      </div>
                      <div class="body-1 d-flex justify-content-center align-items-center mt-2">
                        <v-icon class="title pr-2">mdi-barn</v-icon> {{ farmSelected && farmSelected.name | defaultEmpty }}
                      </div>
                      <div class="body-1 d-flex justify-content-center align-items-center mt-2">
                        <v-icon class="title pr-2">mdi-view-module </v-icon> {{ moduleSelected && moduleSelected.text | defaultEmpty }}
                      </div>
                    </v-col>
                    <v-col sm="8">
                      <div class="body-1 d-flex justify-content-center align-items-center">
                        <v-icon class="title pr-2">mdi-calendar-clock</v-icon> {{ daysOccupationsSelected && daysOccupationsSelected.text | defaultEmpty }}
                      </div>
                      <div class="body-1 d-flex justify-content-center align-items-center mt-2">
                        <v-icon class="title pr-2">mdi-cow</v-icon> {{ animalLabel | defaultEmpty }}
                      </div>
                      <div v-if="supplementConsumption" class="body-1 d-flex justify-content-center align-items-center mt-2">
                        <v-icon class="title pr-2">mdi-food-takeout-box-outline</v-icon>
                        Suplementação de {{ supplementConsumption | number:decimals(2) }} kg de MN/cab/dia.
                      </div>
                    </v-col>
                  </v-row>
                </v-alert>

                <v-row>
                  <v-col sm="4" >
                    <v-card color="success darken-2" dark>
                      <v-card-title class="subtitle-2">Máximo de cabeças</v-card-title>
                      <v-card-text>
                        <div class="py-3">
                          <h1 class="white--text">
                            {{ resultsRaw.results.numberOfHeads | number:decimals(0) }}
                            <span
                              v-if="['FEMEA_+24_PARIDA_PRENHA', 'FEMEA_+24_PARIDA_VAZIA'].includes(form.category_complementary)"
                              class="body-2"></span>
                          </h1>

                          <v-avatar class="icon-bottom-card">
                            <v-icon>mdi-alert-circle-check</v-icon>
                          </v-avatar>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col sm="4">
                    <v-card color="success darken-1" dark>
                      <v-card-title class="subtitle-2">Desempenho previsto</v-card-title>
                      <v-card-text>
                        <div class="py-3">
                          <h1 class="white--text">
                            {{ resultsRaw.results.expectedPerformance | number:decimals(0) }} g/cab/dia
                            <v-tooltip v-if="alertExpectedPerformance" top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="warning"
                                  v-bind="attrs"
                                  size="21"
                                  v-on="on"
                                >
                                  mdi-alert
                                </v-icon>
                              </template>
                              <span>Este pasto não permite o desempenho escolhido.</span>
                            </v-tooltip>
                          </h1>

                          <v-avatar class="icon-bottom-card">
                            <v-icon>mdi-abacus</v-icon>
                          </v-avatar>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col sm="4">
                    <v-card color="success" dark>
                      <v-card-title class="subtitle-2">Peso médio do lote</v-card-title>
                      <v-card-text>
                        <div class="py-3">
                          <h1 class="white--text">{{ resultsRaw.results.averageBatchWeight | number:decimals(1) }} kg</h1>

                          <v-avatar class="icon-bottom-card">
                            <v-icon>mdi-anvil</v-icon>
                          </v-avatar>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col sm="6">
                    <v-card>
                      <v-card-title class="subtitle-2">Dados do módulo e oferta ajustada</v-card-title>
                      <v-data-table
                        :headers="headerModuleDataAndAdjustedOffer"
                        :items="moduleDataAndAdjustedOffer"
                        hide-default-header
                        hide-default-footer
                      >
                        <template #item="{ item }">
                          <tr :class="(item.color || '') + ' text-right'">
                            <td>{{ item.label }}</td>
                            <td>
                              <strong>
                                <template v-if="['crudeProtein', 'fiberInNeutralDetergent'].indexOf(item.name) > -1">{{ item.value * 100 | percent(1) }}</template>
                                <template v-else-if="['availableDryMatter', 'maximumConsumptionPossible'].indexOf(item.name) > -1">{{ item.value | number:decimals(0) }}</template>
                                <template v-else-if="['metabolizableEnergy'].indexOf(item.name) > -1">{{ item.value | number:decimals(2) }}</template>
                                <template v-else-if="item.name !== 'qtdPaddocks'">{{ item.value | number:decimals(1) }}</template>
                                <template v-else>{{ item.value }}</template>
                              </strong>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                  <v-col sm="6">
                    <v-card>
                      <v-card-title class="subtitle-2">Demais índices</v-card-title>
                      <v-data-table
                        :headers="headerResults"
                        :items="results"
                        hide-default-header
                        hide-default-footer
                      >
                        <template #item="{ item }">
                          <tr :class="`text-right ${item.color || ''} ${item.warning ? 'red--text' : ''}`">
                            <td>{{ item.label }}</td>
                            <td style="padding: 0 8px">
                              <strong>
                                <template v-if="item.name === 'arrobasProduced'">{{ item.value | number:decimals(1) }}</template>
                                <template v-else-if="item.name === 'expectedConsumption'">{{ item.value | number:decimals(2) }}</template>
                                <template v-else-if="item.name === 'consumptionByLiveWeight'">{{ item.value | percent }}</template>
                                <template v-else-if="item.name === 'moduleCapacity'">{{ item.value | number:decimals(1) }}</template>
                                <template v-else-if="['instantPicketCharge'].indexOf(item.name) > -1">{{ item.value | number:decimals(1) }}</template>
                                <template v-else>{{ item.value | number:decimals(1) }}</template>
                                <template>
                                  <v-tooltip v-if="item.warning" top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        color="warning"
                                        v-bind="attrs"
                                        size="21"
                                        v-on="on"
                                      >
                                        mdi-alert-outline
                                      </v-icon>
                                    </template>
                                    <span>{{ item.warning }}</span>
                                  </v-tooltip>
                                </template>
                              </strong>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="sm-8">
                    <v-card v-if="$vuetify.breakpoint.xs? false:true">
                      <v-card-title class="title-1">Aviso legal</v-card-title>
                      <v-card-text>
                        <span>
                          Nenhum conteúdo do Pastoreio de Precisão deve ser considerado como recomendação de diminuição/manutenção/aumento da quantidade de animais num lote específico ou na fazenda inteira. Tampouco como garantia de que será alcançado ou deixado de alcançar determinado desempenho por parte do gado.
            Deve, sim, ser considerado como o processamento de dados provenientes de imagens de satélite e sua tradução em números que auxiliam na tomada de decisão.
                        </span>
                      </v-card-text>
                    </v-card>
                    <v-expand-transition v-if="$vuetify.breakpoint.xs? true:false">
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-card>
                            <v-expansion-panel-header>
                              <v-card-title class="title-1">Aviso legal</v-card-title>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-card-text>
                                <span>
                                  Nenhum conteúdo do Pastoreio de Precisão deve ser considerado como recomendação de diminuição/manutenção/aumento da quantidade de animais num lote específico ou na fazenda inteira. Tampouco como garantia de que será alcançado ou deixado de alcançar determinado desempenho por parte do gado.
                                  Deve, sim, se considerado como o processamento de dados provenientes de imagens de satélite e sua tradução em números que auxiliam na tomada de decisão.
                                </span>              
                              </v-card-text>
                            </v-expansion-panel-content>
                          </v-card>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expand-transition>
                  </v-col>
                </v-row>
              </div>
            </transition>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <modal-results ref="modalResults" :results="resultsRaw" />
  </div>
</template>

<style scoped>
.icon-bottom-card {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 2.5rem;
}

.print-only {display:none;}

@media print {
  body :not(.both) {display:none;}
  .print-only  {display:block!important;}
}

</style>
